<template>
    <div>
        <div>
            <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
                <svg fill="#000" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
                    <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
                </svg>
                <span class="special-font" style="color: #000;"> WITHDRAW FUNDS</span>
            </a>
        </div>
        <section class="account-details mt-4">
            <h5 style="color: #000;">Select payment provider</h5>

            <div class="wallet-info">
                <div class="account">
                    <router-link to="/wave-withdraw">
                        <span class="s-a-1-txt">
                            <img style="width: 40px;border-radius: 5px;" src="/img/payment/wave.png" />
                        </span>
                    </router-link>
                    <strong class="text-dark ml-4"> Wave</strong>
                    <strong class="text-dark">
                        <router-link to="/wave-withdraw">
                            <img class="ml-2 mt-2" style="width: 20px; height: 20px;float: right;" src="/img/TopNav/right.png" />
                        </router-link>
                    </strong>
                </div>
            </div>

            <div class="wallet-info">
                <div class="account">
                    <router-link to="/orange-withdraw">
                        <span class="s-a-1-txt">
                            <img style="width: 40px;border-radius: 5px;" src="/img/payment/orange.png" />
                        </span>
                    </router-link>
                    <strong class="text-dark ml-4"> Orange Money</strong>
                    <strong class="text-dark">
                        <router-link to="/orange-withdraw">
                            <img class="ml-2 mt-2" style="width: 20px; height: 20px;float: right;" src="/img/TopNav/right.png" />
                        </router-link>
                    </strong>
                </div>
            </div>

            <div class="wallet-info">
                <div class="account">
                    <router-link to="/mtn-withdraw">
                        <span class="s-a-1-txt">
                            <img style="width: 40px;border-radius: 5px;" src="/img/payment/mtn.jpg" />
                        </span>
                    </router-link>
                    <strong class="text-dark ml-4"> MTN</strong>
                    <strong class="text-dark">
                        <router-link to="/mtn-withdraw">
                            <img class="ml-2 mt-2" style="width: 20px; height: 20px;float: right;" src="/img/TopNav/right.png" />
                        </router-link>
                    </strong>
                </div>
            </div>

            <div class="wallet-info">
                <div class="account">
                    <router-link to="/moov-withdraw">
                        <span class="s-a-1-txt">
                            <img style="width: 40px;border-radius: 5px;" src="/img/payment/moov.png" />
                        </span>
                    </router-link>
                    <strong class="text-dark ml-4"> Moov Money</strong>
                    <strong class="text-dark">
                        <router-link to="/moov-withdraw">
                            <img class="ml-2 mt-2" style="width: 20px; height: 20px;float: right;" src="/img/TopNav/right.png" />
                        </router-link>
                    </strong>
                </div>
            </div>
        </section>
        <span id="open-shareinvite" class="hidden"></span>
    </div>
</template>

<script>

export default {
    name: "Setting",
};
</script>

<style scoped>
.account-details {
    background: var(--white);
    padding: 0.5rem 15px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: calc(100vh - 160px);
}

.account-details>.card {
    background: none;
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, 50px);
}

.modal-dialog {
    position: fixed !important;
    bottom: 0 !important;
    left: 0% !important;
    right: 0% !important;
    margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
    transform: none;
}

.modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--gray-white);

}

.list-active {
    background-color: #F08B05;
}

.title_name {
    color: #fff;
}

.parent {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.parent .banner {
    width: 100%;
    /* height: 48px; */
}

.parent iframe {
    background: #000;
    border: none;
    flex-grow: 1;
}

h4 {
    position: relative;
    margin: 0;
}


.profile-info {
    background: var(--primary-bg-light);
    color: white;
    display: flex;
    border-radius: 0.5rem;
    padding: 1rem;
    align-items: center;
    gap: 0.5rem;
}

.profile-info .menu-icon {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    object-position: center;
    background: white;
    border-radius: 9999px;
    padding: 5px;
}

.profile-info>div {
    display: flex;
    gap: 0.1rem;
    flex-direction: column;
}

.wallet-info,
.wallet-actions {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

}

.wallet-info>div {
    flex: 1;
    background: var(--light-gray);
    border-radius: 0.5rem;
    padding: 1rem;
}


.wallet-actions>button {
    background: var(--red);
    border: 2px transparent solid;
    color: var(--primary);
    padding: 1rem;
    text-align: center;
    border-radius: 0.5rem;
}


.wallet-actions>button:not(.active) {
    border-color: var(--red);
    background: transparent;
    color: var(--red);
}


.wallet-form .card {
    padding: 1rem;
}

.odd-btn {
    background: var(--light-gray);
    color: var(--primary);
    border-radius: 9999px;
    width: auto;
}

.wallet-btns-grid {
    gap: 0.5rem;
    flex-wrap: wrap;
    flex-direction: row;
    width: auto;
}

.form-wrapper1 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


.menu-items {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1.5rem;
}

.section-title {
    margin: 0;
    font-size: 0.8rem;
    color: white;
    font-weight: normal;
}
</style>
